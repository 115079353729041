"use client";

import Cookies from "js-cookie";

import {
  RETURN_VISIT_COOKIE_NAME,
  RETURN_VISIT_INGRESS_URL_COOKIE_NAME,
} from "@shared/constants";

import { logError } from "@shared/functions/log";
import { throwUndefinedVariable } from "@shared/functions/throwError";

type ReturnUserCookie = {
  returnVisitCookie: string;
  returnVisitOriginalIngressCookie: string;
};

export default function getReturningVisitorCookie(): ReturnUserCookie {
  if (!RETURN_VISIT_COOKIE_NAME) {
    throwUndefinedVariable("RETURN_VISIT_COOKIE_NAME");
  }
  if (!RETURN_VISIT_INGRESS_URL_COOKIE_NAME) {
    throwUndefinedVariable("RETURN_VISIT_INGRESS_URL_COOKIE_NAME");
  }

  const returnVisitCookie = Cookies.get(RETURN_VISIT_COOKIE_NAME);
  const returnVisitOriginalIngressCookie = Cookies.get(
    RETURN_VISIT_INGRESS_URL_COOKIE_NAME,
  );

  const cookieValues = {
    returnVisitCookie: "",
    returnVisitOriginalIngressCookie: "",
  };

  if (returnVisitCookie) {
    try {
      cookieValues.returnVisitCookie = returnVisitCookie;
    } catch (e) {
      logError({
        message: "returnVisitCookie was unable to parse cookie",
        event: {
          name: "returnVisitCookie",
          meta: {
            returnVisitCookie,
          },
        },
      });
    }
  }
  if (returnVisitOriginalIngressCookie) {
    try {
      cookieValues.returnVisitOriginalIngressCookie =
        returnVisitOriginalIngressCookie;
    } catch (e) {
      logError({
        message: "returnVisitOriginalIngressCookie was unable to parse cookie",
        event: {
          name: "returnVisitOriginalIngressCookie",
          meta: {
            returnVisitOriginalIngressCookie,
          },
        },
      });
    }
  }

  return cookieValues;
}
