/* eslint-disable react-hooks/rules-of-hooks */
"use client";

import Cookies from "js-cookie";

import { VISIT_COOKIE_MAX_AGE, VISIT_COOKIE_NAME } from "@shared/constants";

import { throwUndefinedVariable } from "@shared/functions/throwError";

import type Visit from "@packages/types/visit";

/* eslint-disable react-hooks/rules-of-hooks */

export default function createVisitCookie(props: Visit.Cookie) {
  if (!VISIT_COOKIE_MAX_AGE) {
    throwUndefinedVariable("VISIT_COOKIE_MAX_AGE");
  }

  if (!VISIT_COOKIE_NAME) {
    throwUndefinedVariable("VISIT_COOKIE_NAME");
  }

  Cookies.set(VISIT_COOKIE_NAME, JSON.stringify(props), {
    // VISIT_COOKIE_MAX_AGE is defined in seconds, `expires` requires days (1d === 86400s)
    expires: VISIT_COOKIE_MAX_AGE / 86400,
    sameSite: "strict",
  });
}
