import isValidUrl from "./isValidUrl";
import { logError, logInfo, logWarning } from "./log";

const EXCLUDED_PARAMS = ["redirect"] as const;
export interface ParsedUrlResult {
  searchParams: Record<string, string>;
  safeReconstructedUrl: string;
  queryString: string;
}

/**
 * Creates a result object with the provided search parameters, safe reconstructed URL, and query string.
 * @param searchParams - The search parameters.
 * @param safeReconstructedUrl - The safe reconstructed URL.
 * @param queryString - The query string.
 * @returns The parsed URL result object.
 */
const createResultObject = (
  searchParams: Record<string, string>,
  safeReconstructedUrl: string,
  queryString: string,
): ParsedUrlResult => ({
  searchParams,
  safeReconstructedUrl,
  queryString,
});

/**
 * Reconstructs a URL from the provided URL input and query string.
 *
 * If the `urlInput` is a `URL` object, it will reconstruct the URL using the `origin`, `protocol`, `pathname`, and `hash` properties, and append the `queryString` if provided.
 *
 * If the `urlInput` is a string, it will split the string on the first `?` character to get the base path, and then append the `queryString` if provided.
 *
 * @param urlInput - The URL input, which can be a string or a `URL` object.
 * @param queryString - The query string to append to the URL, or `null` if no query string is provided.
 * @returns The reconstructed URL.
 */
const reconstructUrl = (
  urlInput: string | URL,
  queryString: string | null,
): string => {
  // Handle URL object case
  if (urlInput instanceof URL) {
    return `${urlInput.origin}${urlInput.pathname}${queryString ? `?${queryString}` : ""}${urlInput.hash}`;
  }

  // Handle string URL/path cases
  const basePath = urlInput.split("?")[0] || urlInput;
  return queryString ? `${basePath}?${queryString}` : basePath;
};

/**
 * Parses the provided URLSearchParams object, handling any errors that may occur during the decoding process.
 * It removes the "redirect" query parameter and returns an object containing the parsed search parameters,
 * a safe reconstructed URL, and the original query string.
 *
 * @param params - The URLSearchParams object to parse.
 * @returns An object with the parsed search parameters, a safe reconstructed URL, and the original query string.
 */
const parseParams = (params: URLSearchParams, referer = "") => {
  const cleansedParams = new URLSearchParams();
  const result: Record<string, string> = {};

  if (referer) {
    cleansedParams.append("cpcExternalReferer", referer);
  }
  let unSafeParamCount = 0;

  for (const param of EXCLUDED_PARAMS) {
    params.delete(param);
  }

  for (const [key, value] of params) {
    try {
      const decodedValue = decodeURIComponent(value);
      const decodedKey = decodeURIComponent(key);
      result[decodedKey] = decodedValue;
      cleansedParams.append(decodedKey, decodedValue);
    } catch (e) {
      logWarning({
        message: `Skipping malformed query parameter "${key}" with "${value}": ${(e as Error)?.message}`,
        error: e,
      });
      unSafeParamCount++;
    }
  }

  return { result, cleansedParams, unSafeParamCount };
};
/**
 * Logs the parsed query parameters from the provided URL if all parameters were successfully decoded.
 *
 * @param resultObject - The object containing the parsed search parameters, safe reconstructed URL, and original query string.
 * @param url - The original URL from which the parameters were parsed.
 * @param unSafeParamCount - The number of parameters that could not be safely decoded.
 */
const logResultIfSafe = (
  resultObject: ParsedUrlResult,
  url: string,
  unSafeParamCount: number,
): void => {
  if (unSafeParamCount === 0) {
    logInfo({
      message: `Parsed query parameters from URL [[ ${url} ]]: ${JSON.stringify(resultObject)}`,
      misc: { result: resultObject },
    });
  }
};

/**
 * Safely parses the query parameters from the provided URL.
 *
 * This function attempts to parse the URL and extract the query parameters, handling any errors that may occur during the decoding process. It removes the "redirect" query parameter, which is used to indicate that the user has been redirected to the current page, to prevent unintentionally creating a new visitId when comparing query strings.
 *
 * @param url - The URL to parse.
 * @param referer - optional referer url to be appended to queryString if present
 * @returns An object containing the parsed search parameters, a safe reconstructed URL, and the original query string.
 */
export const safeParseQueryParams = (
  url: string,
  referer = "",
): ParsedUrlResult => {
  try {
    if (!isValidUrl(url)) {
      throw new Error("Invalid URL format");
    }
    const urlObj = new URL(url);
    const { result, cleansedParams, unSafeParamCount } = parseParams(
      urlObj.searchParams,
      referer,
    );

    const queryString = decodeURIComponent(cleansedParams.toString());
    const safeReconstructedUrl = reconstructUrl(urlObj, queryString);

    const resultObject = createResultObject(
      result,
      safeReconstructedUrl,
      queryString,
    );

    logResultIfSafe(resultObject, url, unSafeParamCount);

    return resultObject;
  } catch (e) {
    try {
      const [basePath, queryString] = url.split("?") || "";
      const { result, cleansedParams, unSafeParamCount } = parseParams(
        new URLSearchParams(queryString),
        referer,
      );

      const cleansedQueryString = decodeURIComponent(cleansedParams.toString());
      const safeReconstructedUrl = reconstructUrl(
        basePath,
        cleansedQueryString,
      );

      const resultObject = createResultObject(
        result,
        safeReconstructedUrl,
        cleansedQueryString,
      );

      logResultIfSafe(resultObject, url, unSafeParamCount);

      return resultObject;
    } catch (e) {
      logError({
        message: `Failed to parse URL query parameters: ${(e as Error)?.message}`,
        error: e,
      });
      return {
        searchParams: {},
        safeReconstructedUrl: reconstructUrl(url, null),
        queryString: "",
      };
    }
  }
};
