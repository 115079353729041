"use client";

import { LAND_DOMAIN_NAME } from "@shared/constants";

import createVisitCookie from "@client/cookies/createVisitCookie";
import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

type Props = {
  domainStats: any;
};

export default function SetDomainStatsCookie({ domainStats }: Props) {
  const visitCookie = getVisitCookie() as Visit.Cookie;

  if (
    visitCookie &&
    (visitCookie?.domainStats === undefined ||
      visitCookie.domainStats.domain !== LAND_DOMAIN_NAME)
  ) {
    createVisitCookie({
      ...visitCookie,
      domainStats: domainStats,
    });
  }

  return <></>;
}
