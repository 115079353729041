const pattern =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+([\-\.]{2}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;

export default function isValidUrl(possibleUrl: string) {
  // supported as of August 5, 2024 for 89% of users
  if (!possibleUrl) return false;
  if (possibleUrl.trim().includes(" ")) return false;

  /**
   * handle edge cases
   */
  if (possibleUrl.includes("..")) return false;
  if (possibleUrl.includes("://.")) return false;

  try {
    new URL(possibleUrl);
    return true;
  } catch {
    // fallback for the other 11% user as of August 5, 2024
    return pattern.test(possibleUrl);
  }
}
