"use client";

import { useParams, usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";

import {
  CYPRESS_REDIRECT_RETURN_FLAG,
  EVENT_JOURNEY_UPDATED,
  EVENT_LANDING_PAGE_VIEWED,
  EXTERNAL_REDIRECT_SITES,
} from "@shared/constants";

import { logDebug } from "@shared/functions/log";
import { safeParseQueryParams } from "@shared/functions/safeParseQueryParams";

import DataLayer from "@client/classes/data-layer/data-layer";
import createVisitCookie from "@client/cookies/createVisitCookie";
import getReturningVisitorCookie from "@client/cookies/getReturningVisitorCookie";
import getVisitCookie from "@client/cookies/getVisitCookie";

import type Visit from "@packages/types/visit";

type Props = {
  visitId?: string;
};

export default function VisitCookie({ visitId }: Props) {
  const params = useParams<{
    journey?: string;
  }>();

  const searchParams = useSearchParams();

  const pathname = usePathname();

  // Early return during SSR when window is not defined
  if (typeof window === "undefined") return null;

  // Early return if we're on the redirect page
  // to prevent visit cookie from being created on redirect page
  const isClientSideRedirectPage =
    pathname.startsWith("/redirect") ||
    (searchParams.get("redirectUrl") && searchParams.get("originalUrl"));

  if (isClientSideRedirectPage) {
    return null;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const visitCookie = getVisitCookie();
    const referredBy = searchParams.get("referredBy");
    const hasCypressFlag = searchParams.get(CYPRESS_REDIRECT_RETURN_FLAG);

    const externalSites = hasCypressFlag
      ? ["google.com", ...EXTERNAL_REDIRECT_SITES]
      : EXTERNAL_REDIRECT_SITES;

    let isAllowedExternalReferer = false;
    let externalReferer = "";
    if (
      referredBy &&
      externalSites.some((site) => {
        const isKnownExternalRedirect = referredBy?.includes(site);

        if (isKnownExternalRedirect) {
          externalReferer = site;
        }

        return isKnownExternalRedirect;
      })
    ) {
      isAllowedExternalReferer = true;
    }

    // Check for existence of returningVisitor and returningVisitorOriginalIngressUrl cookies
    let DEFER_TO_ORIGINAL_INGRESS_URL = false;

    const returnUserDetails = getReturningVisitorCookie();
    const { returnVisitOriginalIngressCookie, returnVisitCookie } =
      returnUserDetails || {};
    if (returnVisitCookie && returnVisitOriginalIngressCookie) {
      DEFER_TO_ORIGINAL_INGRESS_URL = isAllowedExternalReferer;
    }

    const { queryString: mostRecentQueryString, safeReconstructedUrl } =
      safeParseQueryParams(
        DEFER_TO_ORIGINAL_INGRESS_URL
          ? returnVisitOriginalIngressCookie
          : window.location.href,
        externalReferer,
      );

    // NEW INGRESS ----------------------------------------------
    const newIngress = !visitCookie?.unalteredIngressUrl;

    //JOURNEY ----------------------------------------------
    const journeyChanged =
      params.journey && visitCookie?.journey
        ? params.journey !== visitCookie?.journey
        : false;

    const journey = journeyChanged
      ? params.journey
      : visitCookie?.journey || params.journey;

    //QUERY STRING ----------------------------------------------
    let queryStringChanged = false;
    const visitCookieQueryString = visitCookie?.queryString || "";

    // TODO: update to evaluate if the mostRecentQueryString contains attribution params
    // if it does, are those different from the ingress url?
    // if so, we need to update the visit cookie with the new query string
    if (
      mostRecentQueryString &&
      visitCookieQueryString &&
      visitCookieQueryString !== ""
    ) {
      queryStringChanged = mostRecentQueryString !== visitCookieQueryString;
    }

    const queryString = queryStringChanged
      ? mostRecentQueryString
      : visitCookieQueryString || mostRecentQueryString;

    //NEW VISIT ----------------------------------------------
    const newVisit =
      !visitCookie || journeyChanged || queryStringChanged || newIngress;

    //LANDING PAGE ----------------------------------------------
    const landingPage = newVisit
      ? pathname // Use pathname from Next.js hook instead of window.location
      : visitCookie?.landingPage;

    //INGRESS URL ----------------------------------------------
    const unalteredIngressUrl = newVisit
      ? safeReconstructedUrl
      : visitCookie?.unalteredIngressUrl;

    //VISIT ID ----------------------------------------------

    // The server handles this on the first request, so we do not need to
    // predicate this on newIngress
    const generateNewVisitId =
      !visitCookie || journeyChanged || queryStringChanged;

    // This should never absent on the cookie, as the server will create it on
    // the first request to the server (initial page load) in the middleware.
    const vId = generateNewVisitId ? uuid() : visitCookie?.visitId;

    // GEO LOCATION ----------------------------------------------
    const geoLocation = visitCookie?.geoLocation || ({} as Visit.Geolocation);

    logDebug({
      message: "VisitCookieLogic for debug",
      event: {
        name: "VisitCookieLogic",
        meta: {
          newVisit,
          visitCookie,
          journey,
          journeyChanged,
          paramsJourney: params.journey,
          visitCookieJourney: visitCookie?.journey,
          queryStringChanged,
          mostRecentQueryString,
          visitCookieQueryString,
          queryString,
          newIngress,
          landingPage,
          unalteredIngressUrl,
          generateNewVisitId,
          vId,
          geoLocation,
        },
      },
    });

    // COOKIE CREATE / UPDATE ----------------------------------------------
    const updatedVisitCookie: Visit.Cookie = {
      ...visitCookie,
      geoLocation,
      journey,
      landingPage,
      queryString: DEFER_TO_ORIGINAL_INGRESS_URL
        ? mostRecentQueryString
        : queryString,
      unalteredIngressUrl: DEFER_TO_ORIGINAL_INGRESS_URL
        ? safeReconstructedUrl
        : unalteredIngressUrl,
      visitId: vId,
    };

    createVisitCookie(updatedVisitCookie);

    // FIRE EVENTS ----------------------------------------------
    if (newIngress) DataLayer.events.queue(EVENT_LANDING_PAGE_VIEWED);
    if (journeyChanged) DataLayer.events.queue(EVENT_JOURNEY_UPDATED);
  }, [params, searchParams, pathname, visitId]);

  return null;
}
